import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import contact from '../../assets/images/contact.png'
import Multiselect from '../MultiSelect/MultiSelect';
import DialogBox from '../DialogBox/DialogBox';
import toast, { Toaster } from 'react-hot-toast';
import {Link} from 'gatsby'

const productList = [
    { value: "consulting", label: "Consulting" },
    { value: "software_development", label: "Software Development" },
    { value: "data_science", label: "IoT, IIoT, Data Science" },
    { value: "software_integration", label: "Software Integration" },
    { value: "ecommerce", label: "eCommerce Platform" }
];

const ContactForm = () => {
    
    const [loading, setLoading] = React.useState(false);
    const [responsedata, setresponseData] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const [name, setCustomerName] = React.useState("");
    const [customer_mail, setCustomerMail] = React.useState(false);
    const [phone, setPhoneNumber] = React.useState("");
    const [products, setProducts] = React.useState([]);
    const [message, setMessage] = React.useState("");

    const clearfields=()=>{
        const inputs = document.querySelectorAll('#newCustomer_name, #newCustomer_email, #newCustomer_phone, #newCustomer_message, #newCustomer_message');

        inputs.forEach(input => {
          input.value = '';
        });
    }

    const handleSubmit=(e)=>{
        e.preventDefault();

        setLoading(true)
        // const customer= { newCustomer_email: customer_mail,newCustomer_products:products}
        const customer= { newCustomer_name:name,newCustomer_phone:phone,newCustomer_email: customer_mail,newCustomer_products:products,newCustomer_message:message}
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(customer),
        }
        
        fetch(`${process.env.API}/public/new-customer`, requestOptions)
        .then(async response => {
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data = isJson && await response.json();

            // check for error response
            if(!response.ok) {
                // get error message from body or default to response status
                const error = (data && data.message) || response.status;
                console.log(error)
                setIsError(true)
                setLoading(false);
                // toast.error("Error! Details not stored.")
                return Promise.reject(error);
                
            }
            //console.log(data)
            setresponseData(response.body)
            setLoading(false);
            //declare success
            if(data.ok){
                toast.success(data.message)
            }
            else{
                toast.error(data.message)
            }
            clearfields()
             
        })
        // .then(data => this.setState({ postId: data.id }))
        .catch(err => {
              console.log("ERROR!")
              console.log(err);
              setLoading(false);
              toast.error("Error! Details not stored.")
    
        });

    }

    return (
        <section className="contact-area pb-100">
           
            <div>
            <Toaster
                position="top-center"
                reverseOrder={false}
                />
                 {/* <DialogBox message={responsedata}/> */}
            </div>
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="contact" /> 
                        Get in Touch
                    </span>
                    <h2>Ready to Get Started?</h2>
                    <p>Complete the form below to get started. Not sure what your business needs? Schedule an appointment today, our team is ready to work with you.</p>
                    <Link to="/appointment" className="d-block" target="_blank" rel="noreferrer">
                        <button type="submit" className="default-btn">
                            <i className="flaticon-calendar"></i> 
                            Schedule Appointment<span></span>
                        </button>
                    </Link>
                </div>

                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="contact-image">
                            <img src={contact} alt="contact" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                   
                        <div className="contact-form">
                            <form id="contactForm"onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <input type="text" name="newCustomer_name" id="newCustomer_name" className="form-control" required placeholder="Your name*" onChange={e => setCustomerName(e.target.value)}/>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <input type="email" name="newCustomer_email" id="newCustomer_email" className="form-control" required placeholder="Your email address*" onChange={e => setCustomerMail(e.target.value)}/>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <input type="text" name="newCustomer_phone" id="newCustomer_phone" className="form-control" required placeholder="Your cellphone number" onChange={e => setPhoneNumber(e.target.value)} pattern="^(\+27|0)[6-8][0-9]{8}$"/>
                                        </div>
                                    </div>

                                     <div className="col-lg-12 col-md-12">
                                        <p>Select from list of currently available services*</p>
                                        <div className="form-group">
                                            <Multiselect name="newCustomer_products" className="form-control" cols="30" rows="6" 
                                            required  
                                            options={productList}
                                            selected={products}
                                            setSelected={setProducts} />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <textarea name="newCustomer_message" id="newCustomer_message" className="form-control" cols="30" rows="6" required placeholder="Tell us a little bit more about your idea or project..." onChange={e => setMessage(e.target.value)}/>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                        {responsedata.ok ? '' : ''}<span></span>
                                        </div>
                                        
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <button type="submit" className="default-btn">
                                            <i className="flaticon-tick"></i> 
                                            {loading ? 'Submitting request…' : 'Send Message'}<span></span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactForm